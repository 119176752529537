import React from 'react';
import { FooterProps } from './Footer.props';
import { FooterResources } from './Footer.resources';
import styles from './Footer.module.scss';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { changeLangAction } from '../../redux/reducers/language/changeLangAction';
import { useSelector } from 'react-redux';
// @ts-ignore
import legalOpinionAz from "../../assets/huquqiRəy.pdf";
// @ts-ignore
import legalOpinionEn from "../../assets/legalOpinion.pdf";
// @ts-ignore
import UserAgreementAndPrivacyPolicy from "../../assets/doc/UserAgreementAndPrivacyPolicy.docx";

const Footer: React.FC<FooterProps> = () => {
    const lang = localStorage.getItem("language");
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const setLanguage = (lang: any) => {
        changeLang(lang);
        i18n.changeLanguage(lang, (err: any, t: any) => {
            if (err) return console.log("something went wrong loading", err);
            t("key"); // -> same as i18next.t
        });
    };

    const handleSelectChange = (event: any) => {
        setLanguage(event);
    };

    const changeLang = (language: string) => {
        let langKey: string;
        if (language === "ru") {
            langKey = "valueRu";
        } else if (language === "az") {
            langKey = "valueAz";
        } else langKey = "valueEn";
        dispatch(changeLangAction(language, langKey));
    };
    const { language } = useSelector((state: any) => state.languageReducer);


    const translations = {
        en: '<b>TAX ID</b>: 1006028471<br> <b>Post Address</b>: Azerbaijan, Baku city, Ayaz İsmayılov street 8, AZ 1025<br>Legal Address: AZ1111, Baku, Nasimi district, Javad Khan 4MKR, house 23A, apartment 40',
        ru: '<b>ИНН</b>: 1006028471<br> <b>Почтовый адрес</b>: Азербайджан, город Баку, улица Аяза Исмаилова 8, AZ 1025<br>Юридический Адрес: АZ1111, город Баку, Насиминский район, Джавад Хан 4 МКР, дом 23А, квартира 40',
        az: '<b>VÖEN</b>: 1006028471<br>Ünvan: Azərbaycan, Bakı şəhəri, Ayaz İsmayılov küçəsi 8, AZ 1025<br>AZ1111, Bakı şəhəri Nəsimi rayonu, Cavad Xan 4 MKR, ev 23A, mənzil 40'
    };

    const licenseTranslations = {
        en: 'Ministry of Digital Development and Transport of AR <br> Formation of personal data information resources and creation of information systems providing services to them.',
        ru: 'Министерство цифрового развития и транспорта АР <br> Формирование информационных ресурсов персональных данных и создание информационных систем, оказывающих им услуги.',
        az: 'AR Rəqəmsal İnkişaf və Nəqliyyat Nazirliyi <br> Fərdi məlumatların informasiya ehtiyatlarının formalaşdırılması və informasiya sistemlərinin yaradılması, onlara xidmətlərin göstərilməsi.'
    }

    return (
        <footer className={styles.footer}>
            <div className='container'>
                <div className={styles['footer__inner']}>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <img src={FooterResources.bLogo} alt='' className={styles['footer__logo']} />

                            {/* <p className={styles['footer__text']}>
                                {t("PulPal electronic payment")}
                            </p> */}
                            {language === 'ru' ? <p className={styles['footer__text']} dangerouslySetInnerHTML={{ __html: translations.ru }}  ></p> : ""}
                            {language === 'en' ? <p className={styles['footer__text']} dangerouslySetInnerHTML={{ __html: translations.en }} ></p> : ""}
                            {language === 'az' ? <p className={styles['footer__text']} dangerouslySetInnerHTML={{ __html: translations.az }} ></p> : ""}

                            {language === 'ru' ? <p className={styles['footer__license']} dangerouslySetInnerHTML={{ __html: licenseTranslations.ru }}  ></p> : ""}
                            {language === 'en' ? <p className={styles['footer__license']} dangerouslySetInnerHTML={{ __html: licenseTranslations.en }} ></p> : ""}
                            {language === 'az' ? <p className={styles['footer__license']} dangerouslySetInnerHTML={{ __html: licenseTranslations.az }} ></p> : ""}

                            <div className={styles['footer__social']}>
                                <ul>
                                    <li>
                                        <a href='https://www.facebook.com/pulpalxezine/'><img src={FooterResources.facebookLogo} alt='' /></a>
                                    </li>
                                    {/* <li>
                                        <a href='#'><img src={FooterResources.instagramLogo} alt='' /></a>
                                    </li> */}
                                    <li>
                                        <a href='https://www.linkedin.com/company/pulpal'><img src={FooterResources.linkedInSvg} alt='' /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href='https://www.youtube.com/@pulpal5683'><img src={FooterResources.youTubeSvg} alt='' /></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-3 col-lg-2'>
                            <div className={styles['footer__title']}>{t("CONTACTS")}</div>
                            <a href='tel:+994776778777' className={styles['footer__links']}>
                                <img src={FooterResources.phoneSvg} alt='' />+994 77 677 87 77
                            </a>
                            <a href='mailto:office@pulpal.az' className={styles['footer__links']}>
                                <img src={FooterResources.mailSvg} alt='' />office@pulpal.az
                            </a>
                        </div>
                        <div className='col-12 col-sm-6 col-md-3 col-lg-2'>
                            <div className={styles['footer__title']}>{t("LANGUAGES")}</div>
                            <input className={styles['footer__links']} type="button" onClick={() => handleSelectChange('ru')} value='Русский' />
                            <input className={styles['footer__links']} type="button" onClick={() => handleSelectChange('az')} value='Azərbaycan dili' />
                            <input className={styles['footer__links']} type="button" onClick={() => handleSelectChange('en')} value='English' />
                        </div>
                        <div className='col-12 col-sm-6 col-md-3 col-lg-2'>
                            <div className={styles['footer__title']}>{t("RULES")}</div>
                            <a className={styles['footer__links']} href={UserAgreementAndPrivacyPolicy} download="UserAgreementAndPrivacyPolicy.docx">{t("userAgreementAndPrivacyPolicy")}</a>
                            {lang === 'az'
                                ? <a href={legalOpinionAz} download="LegalOpinionAZ.pdf" className={styles['footer__links']}>{t("Legal opinion")}</a>
                                : <a href={legalOpinionEn} download="LegalOpinionEN.pdf" className={styles['footer__links']}>{t("Legal opinion")}</a>
                            }
                        </div>
                        <div className='col-12 col-sm-6 col-md-3 col-lg-2'>
                            <div className={styles['footer__title']}>{t("DOCUMENTS")}</div>
                            <a href={`https://docs.pulpal.az/index_${lang}.html#pulpal-prefiksi-il-inteqrasiya`} className={styles['footer__links']}>{t("Connect via API")}</a>
                            <NavLink to='/modules' className={styles['footer__links']}>{t("Ready modules")}</NavLink>
                            <a href='' className={styles['footer__links']} data-bs-toggle='modal' data-bs-target='#askQuestionModal'>{t("To ask a question")}</a>
                        </div>
                    </div>
                    <div className={styles['footer__credits']}>
                        PulPal MMC © 2023
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;